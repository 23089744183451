@import "vars";

.start {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .button-block {
    margin: 1em 0 0;
    opacity: 0;
    transition: 0.3s ease-out;
    text-align: center;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    & > .button {
      border-radius: 50%;
      padding: 0;
      height: 74px;
      width: 74px;

      & > a {
        display: inline-block;
        line-height: 71px;
      }
    }
  }


  svg {
    display: inline-block;
    height: auto;
    pointer-events: all;
    max-width: 800px;
    width: 100%;

    &.done {
      * {
        fill: #ffffff !important;
        stroke: 0;
        transition: 0.5s ease-out;
      }
    }

    &.show {
      * {
        fill: #fff !important;
        transition: 0.5s ease-out;
      }

    }
  }

  & > .logo {
    margin: -100px 0 0 -400px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 800px;
  }
}


.no-mobile {
  .start {
    .button-block {
      & > .button {
        &:hover {
          transition: background-color 0s ease-out 0.4s;

          & > a {
            color: $white;
          }
        }
      }
    }
  }
}


.start-top {
  background: #111;
  height: 50vh;
  position: relative;
  transition: 0.5s ease-out;

  &.open {
    transform: translateY(-100%);
  }

  .logo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.start-bottom {
  background: #111;
  height: 50vh;
  position: relative;
  transition: 0.5s ease-out;

  &.open {
    transform: translateY(100%);
  }

  .logo {
    text-align: center;
  }
}



