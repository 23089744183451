$containerWidth: 1200px;
$mobileFixedHeaderHeight: 51px;
$desktopFixedHeaderHeight: 30px;

$isAdaptive: true;

$fontName: "Roboto";
$fontSizeBase: 14px;

$white: #fff;
$black: #111;

$linkColor: #888;             /* Цвет ссылок */
$linkColor--hover: #333;      /* Цвет ссылок при наведении */
$linkColor--hover2: #333;

$webkitHighlightTapColor: rgba(0,0,0, 0.15);

$textColor: #333;                           /* Цвет текста */
$grey: #666;
$pink: #d9017f;
$green: #9ed178;
$lightGreen: #9ed178;
$violet: #cf98de;
$sand: #f6846a;
$transSand: rgba(246,132,106,0.8);
$lightGrey: #f3f3f3;
$transGrey: rgba(255,255,255,0.8);
$blue: #67b0d6;
$lightBlue: #bae4f9;

$iconColor: $linkColor--hover;

$bgColor: #fff;                        /* Цвет фона */
$headerTopBgColor: #111;               /* Цвет фона верхней шапки */
$headerTopBgColorMobile: #000;         /* Цвет фона верхней шапки на мобильных */
$headerBottomBgColor: #000;            /* Цвет фона нижней шапки  */


$footerTopBgColor: #333;
$footerBottomBgColor: #111;
$footerLinkColor: #888;


/* Цвета ошибок */
$colorError: rgba(253, 31, 36, 0.7);

/* Цвета ОК */
$colorOk: rgba(92, 253, 5, 0.7);



/* Borders */
$borderColor: #ccc;
$borderRadius: 0;               /* Закругления */
$photoBorderRadius: 0;
$border: 1px solid $borderColor;


/* Вертикальный градиент вверх */
@mixin gradient($startColor, $endColor, $gradiend) {

  @if($gradiend == false) {
    background: $startColor;
  }
  @else {
    background: $startColor;
    background: linear-gradient(to top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor,endColorstr=$endColor, GradientType=0 );
  }
}


/* Кнопки */
$isButtonGradient: false;                /* Градиент да/нет? Если нет, используется цвет 1 */
$isButtonShadow: false;                 /* Тень, да/нет          */
$isButtonBorder: true;                 /* Рамка, да/нет          */

$buttonBgColor1: $white;                 /* Цвет фона 1 (нижний)  */
$buttonBgColor2: $white;                 /* Цвет фона 2 (верхний) */
$buttonBorder: 1px solid $black;       /* Рамка кнопок          */
$buttonShadow: 1px 2px 3px 0 #a8a8a8;   /* Тень у кнопок         */
$buttonColor: $black;                     /* Цвет текста кнопок    */
$buttonBorderRadius: $borderRadius;     /* Закругления           */
$buttonBorderColor: $black;

// наведение
$buttonBgColor1--hover: $black;
$buttonBgColor2--hover: $black;
$buttonColor--hover: $white;
$buttonBorderColor--hover: $black;
$buttonShadow--hover: none;

// актив
$buttonBgColor1--active: #d16961;
$buttonBgColor2--active: #d16961;
$buttonColor--active: $white;
$buttonBorderColor--active: #1b9fae;
$buttonShadow--active: none;



@mixin button() {

  @if($isButtonGradient == true) {
    background: linear-gradient(to top, $buttonBgColor1, $buttonBgColor2);

  }
  @else {
    background: $buttonBgColor1;
  }


  &.disabled {
    @include gradient(#aaa, #999, true);
    cursor: default;
    pointer-events: none;
  }

  border-radius: $borderRadius;

  @if($isButtonShadow) {
    box-shadow: $buttonShadow;
  }

  @if($isButtonBorder) {
    border: $buttonBorder;
  }
  @else {
    border: none;
  }

  @if($buttonBorderRadius != 0) {
    border-radius: $buttonBorderRadius;
  }

  &.--inverse {
    background: $buttonBgColor1--hover;
    color: #fff;
  }

  /* Стили кнопки по-умолчанию */
  display: inline-block;
  color: $buttonColor;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  outline: none;
  overflow: hidden;
  padding: 8px 14px;
  position: relative;
  pointer-events: all;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: 0.5s ease-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
  -webkit-appearance: none;
  
  @content;
}



/* Инпуты  */
$isInputBorder: true;                    /* Есть ли рамка */
$isInputShadow: false;                   /* Есть ли тень  */

$inputColor: #888;                       /* Цвет текста   */
$inputBg: $lightGrey;                    /* Цвет фона     */
$inputBorderColor: #888;                 /* Цвет рамки    */
$inputShadow: inset 0 2px 7px 0 #a6a6a7; /* Тень          */


@mixin input() {

  background: $inputBg;

  @if($isInputBorder == true) {
    border: 1px solid $inputBorderColor;
  }

  @if($isInputShadow) {
    box-shadow: $inputShadow;
  }

  border-radius: 0;
  color: $inputColor;
  display: block;
  font-size: 14px;
  height: 40px;
  padding: 8px 18px;
  outline: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @content;

  &:focus {
    box-shadow: 1px 1px 8px rgba(173, 173, 173, 0.6);
  }
}



/* Модули */
$moduleBg: #fff;                            /* Цвет фона модулей                      */
$moduleMobileHeaderBg: #c1c1c1;             /* Цвет фона заголовков модулей в мобайл  */
$moduleHeaderBg: $linkColor;                /* Цвет фона модулей                      */
$moduleHeaderColor: #fff;                   /* Цвет текста в шапке                    */
$moduleMobileHeaderColor: #2c2c2c;          /* Цвет текста в шапке                    */
$moduleBorder: 0;                           /* Рамка                                  */
$module_top__box-shadow: 0px 2px 3px #808080;

@mixin module($isMobile) {

  @if($isMobile == false) {
    background: $moduleBg;
  }
  
  margin: 1em 0 2em;

  @if($moduleBorder != 0) {
    border: $moduleBorder;
  }


  @content;
}



/*********************************************/
/* Горизонтальное меню */
$isTopMenuGradient: false;              /* Градиент да/нет? Если нет, используется цвет 1 */
$isTopMenuShadow: false;                /* Тень, да/нет          */
$isTopMenuBorder: false;                /* Рамка, да/нет         */
$isTopMenuBorderRounded: false;         /* Закругления, да/нет   */

$topMenuBgColor1: #fff;              /* Цвет фона 1 (нижний)  */
$topMenuBgColor2: #fff;              /* Цвет фона 2 (верхний) */
$topMenuBorder: 1px solid #aaa;         /* Рамка                 */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;  /* Тень у кнопок         */
$topMenuColor: #fff;                    /* Цвет текста           */
$topMenuBorderRadius: 0;                /* Закругления           */
$topMenuColor--hover: #edac0a;          /* Цвет текста на ховер  */

$topMenuBgColor1--selected: #fff;    /* Цвет фон 1 на текущий пункт */
$topMenuBgColor2--selected: #fff;    /* Цвет фон 2 на текущий пункт */

@mixin navtop() {

  @content;
}


@mixin fontawesome($n) {

  &:before {
    @if($n != '') {
      content: $n;
    }
    @else {
      content: '';
    }

    color: $linkColor;
    font: normal 18px/18px "FontAwesome";
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -9px;
    top: 50%;
    left: 50%;

    @content;

    z-index: 2;
  }
}



@mixin sideBorder($side: 'left') {
  &:after {
    background: $headerTopBgColorMobile * 1.2;
    border-right: 1px solid $headerTopBgColorMobile * 0.8;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    #{$side}: 0;
    width: 1px;

    @content;
  }
}


/* Товар */
$prodTitleColor: $linkColor;
$prodTextColor: $textColor;
$prodSecondColor: #8f8f8f;
$prodCurrentPriceColor: $black;
$prodOldPriceColor: #a4a4a4;



.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

